import { EnvConst } from 'consts/commonConst'

// prod環境
export const envConst: EnvConst = {
  ENVIRONMENT: 'production',
  DATASET_ID: 4857669202114516,
  FACILITY_NAME: '浪江工場',
  BACKEND_API_ROOT_URL:
    'https://j720cs6a9k.execute-api.ap-northeast-1.amazonaws.com',
  CDF_PROJECT: 'ak-h2'
}
